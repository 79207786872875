import { getRequestEvent, isServer } from 'solid-js/web';
import { createClient } from './create-client';

const browserClient = createClient({
	'x-troon-client-version': (isServer ? import.meta.env.VITE_GIT_SHA : window.__CTX__?.sha) ?? '',
});

export function getApiClient() {
	if (isServer) {
		return getRequestEvent()!.locals.client;
	}
	return browserClient;
}
