import { getCurrentScope } from '@sentry/solidstart';
import { Kind } from 'graphql';
import { mapExchange } from '@urql/core';
import type { Exchange } from '@urql/core';

export const sentryExchange: Exchange = mapExchange({
	onError(error, operation) {
		// Ignore loggedInUser queries, as they're expected to fail when not logged in
		if (
			error?.graphQLErrors.some((err) => err.extensions.unauthorized) ||
			operation.query.definitions.some(
				(def) => def.kind === Kind.OPERATION_DEFINITION && def.name?.value === 'loggedInUser',
			)
		) {
			return;
		}

		const scope = getCurrentScope();

		scope.setTag('kind', operation.kind);
		scope.setExtra('query', operation.query.loc?.source.body);
		scope.setExtra('variables', operation.variables);

		for (const err of error.graphQLErrors) {
			const { message, ...rest } = err;
			scope.setExtra('path', rest.path);
		}
	},
});
